import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpAssocies from "../blocs/Associes"
import KdpAriane from "../blocs/Ariane"

const ariane = [
  { name: 'Les associés', href: '', current: true },
]

export default function Associes() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Les associés de CEOS Avocats</title>
        <meta name="description" content="Antony VANHAECKE, Véronique BENTZ, Audrey MOYSAN" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane
          filariane={ariane}
        />
        <KdpAssocies />
      </main>
      <footer>
        <KdpFooter />
      </footer>
    </div>
  );
}

