import React from "react"
import Associe1 from "../images/EGERIE-CEOS-01J.jpg"
import Associe2 from "../images/EGERIE-CEOS-13J.jpg"
import Associe3 from "../images/EGERIE-CEOS-24J.jpg"
import Associe4 from "../images/EGERIE-CEOS-33J.jpg"

export default function Associes() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 bg bg-[url('../images/ceos-pattern.png')] grayscale brightness-200 opacity-20"
          width={404}
          fill="none"
          viewBox="0 0 404 4000"
          aria-hidden="true"
        >
        </svg>


        <h1 className=" text-3xl font-extrabold tracking-tight sm:text-4xl">Les 3 avocats animés d’une même vision de leur exercice professionnel </h1>

        <div className="mt-20 relative space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="lg:col-span-2">
            <ul
              className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
            >
              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="aspect-w-3 aspect-h-4 sm:aspect-w-3 sm:aspect-h-4">
                    <img className="object-cover shadow-lg rounded-lg" src={Associe4} alt="" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Antony VANHAECKE</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500 text-justify">Antony VANHAECKE est diplômé de l’Université de Paris II Panthéon-Assas (DESS Contentieux et Arbitrage, maîtrise en droit mention carrières judiciaires, certificat de sciences criminelles) et de l’Ecole de Formation du Barreau de Paris. Ayant d’abord exercé au Barreau de Chartres en qualité de collaborateur de la société d’avocats Fidal, il est membre du Barreau de Lyon depuis 1998.</p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://fr.linkedin.com/in/antony-vanhaecke-b8245668" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="aspect-w-3 aspect-h-4 sm:aspect-w-3 sm:aspect-h-4">
                    <img className="object-cover shadow-lg rounded-lg" src={Associe1} alt="" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Véronique BENTZ</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500 text-justify">Véronique BENTZ est diplômée de l’Université de Paris II Panthéon-Assas (DEA Propriété littéraire, artistique et industrielle, maîtrise en droit mention droit privé) et de l’Ecole de Formation du Barreau de Paris. Elle est inscrite au Barreau de Lyon depuis 2006, après avoir exercé au Barreau de Rouen.</p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://fr.linkedin.com/in/v%C3%A9ronique-bentz-46172a9b" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              <li className="sm:py-8">
                <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                  <div className="aspect-w-3 aspect-h-4 sm:aspect-w-3 sm:aspect-h-4">
                    <img className="object-cover shadow-lg rounded-lg" src={Associe2} alt="" />
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Audrey MOYSAN</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500 text-justify">Audrey MOYSAN est diplômée de l’Université de Rouen (M2 Droit de l’entreprise spécialité Droit social), de l’Université de Paris II Panthéon-Assas (maîtrise en droit social) et de l’Ecole de Formation du Barreau de Paris. Elle est inscrite au Barreau de Nantes depuis 2016, après avoir exercé au Barreau de Paris.</p>
                      </div>
                      <ul className="flex space-x-5">
                        <li>
                          <a href="https://fr.linkedin.com/in/audrey-moysan-505071105" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>






            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

